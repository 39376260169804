import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useSiteMetadata } from "web-common/src/hooks/useSiteMetadata";
import "./styles.scss";

const AdChoices: FunctionComponent = () => {
  const { evidonCompanyID, evidonOCID } = useSiteMetadata();
  const newEvidonCompanyID = window.location.pathname === '/us/en/virtualstyler/' ? '6528' : evidonCompanyID;

  const [location] = useState(() => window.location.pathname);

  useEffect(() => {
    document.getElementById('ad-choice-script')?.remove();
    document.getElementById('evidon-settings')?.remove();
    document.getElementById('evidon-location')?.remove();
    document.getElementById('evidon-notice')?.remove();
    document.getElementById('evidon-themes')?.remove();
    document.getElementById('ad-choice-script')?.remove();
    document.querySelector("script[src='https://c.evidon.com/sitenotice/evidon-preferences-dialog.js']")?.remove();
    document.getElementsByClassName('evidon-consent-link')[0]?.remove();

    const evidonScript = document.createElement('script');
    evidonScript.src = 'https://c.evidon.com/dg/dg.js';

    const vendorsScript = document.createElement('script');
    vendorsScript.src = 'https://www.tresemme.com/us/en/vendors.js';
    vendorsScript.id = 'ad-choice-script';
    vendorsScript.setAttribute('data-ev-tag-pid', '21143');
    vendorsScript.async = true;

    if (location.includes('/virtualstyler')) {
      document.querySelectorAll("script[companyid='2523']").forEach((item) => {
        item.remove();
      });
      evidonScript.setAttribute('companyid', '6528');
      vendorsScript.setAttribute('data-ev-tag-ocid', '6528');
    } else {
      document.querySelectorAll("script[companyid='6528']").forEach((item) => {
        item.remove();
      });
      evidonScript.setAttribute('companyid', '2523');
      vendorsScript.setAttribute('data-ev-tag-ocid', '2523');
    }
    document.head.appendChild(evidonScript);
    document.head.appendChild(vendorsScript);
    document.getElementsByClassName('evidon-consent-link').length &&
      document.getElementsByClassName('evidon-consent-link')[0].remove();

    const script = document.createElement('script');
    script.src = window.location.pathname === '/us/en/virtualstyler/' ? '/us/en/virtual-styler.js' : '/us/en/vendors.js';
    script.id = 'ad-choice-script';
    script.setAttribute('data-ev-tag-pid', evidonOCID);
    script.setAttribute('data-ev-tag-ocid', location.includes('/virtualstyler') ? 6528 : evidonCompanyID);
    script.setAttribute('async', 'true');
    script.onload = scriptLoaded;

    document.head.appendChild(script);
  }, [location])

  const preventEvent = (event: Event) => {
    event.preventDefault();
  }

  const scriptLoaded = () => {
    const element = document.getElementsByClassName('evidon-consent-link')
    if (element.length > 0) {
      element[0].addEventListener("click", (event) => preventEvent(event));
    } else {
      setTimeout(() => {
        scriptLoaded()
      }, 1000)
    }
  }

  return (
    <div className="us-c-adchoice">
      <span className="evidon-notice-link"></span>
    </div>
  )
    ;
};

export default AdChoices;